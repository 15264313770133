<template>
  <div id="add-service-suppliers">
    <v-container>
      <h1 class="mt-4">Add Service Supplier Details</h1>
      <ServiceSuppliersForm
        :initial-supplier="supplier"
        @save="createNewSupplier"
      />
      <Alert
        :showAlert="saveClicked.isClicked"
        :type="saveClicked.type"
        allowDismiss
        absolute
        :text="saveClicked.message"
        :inPage="false"
        :backgroundColour="true"
      />
    </v-container>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'
import { Alert } from '@nswdoe/doe-ui-core'
import ServiceSuppliersForm from '@/views/ServiceSuppliers/form'
import { DEFAULT_SUPPLIER } from '@/constants/initial'

export default {
  name: 'AddServiceSuppliers',
  title: 'Add Service Suppliers - SAIS Operation UI Portal',
  components: {
    Alert,
    ServiceSuppliersForm,
  },
  data() {
    return {
      supplier: cloneDeep(DEFAULT_SUPPLIER),
      saveClicked: {
        isClicked: false,
        isSuccess: false,
        message: '',
        type: 'success',
      },
    }
  },
  methods: {
    createNewSupplier(item) {
      this.$store.dispatch('moduleSuppliers/postSupplier', item).then((response) => {
        this.saveClicked = {
          isClicked: true,
          type: response.isError ? 'error' : 'success',
          isSuccess: !response.isError,
          message: response.isError
            ? response.errorMessage
            : 'The changes have been saved successfully',
        }

        if (!response.isError) {
          this.$router.push('/serviceregistry/servicesuppliers')
        }
      })
    },
  },
}
</script>
